<template>
  <div class="container">
    <!-- chat right -->
    <div
      class="chat-right px-3 is-flex is-flex-direction-row is-flex-direction-row-reverse is-align-items-flex-start"
    >
      <div class="picture">
        <figure>
          <p class="image is-64x64">
            <img
              src="@/assets/img/profilepictures/PR-Kooikerhondje.png"
              class="is-rounded"
            />
          </p>
        </figure>
      </div>
      <div class="nameandchat has-text-right has-text-white m-2">
        <div>
          <p>
            PR-Kooikerhondje
          </p>
        </div>
        <div class="box px-4">
          <p>
            <strong>Hi, how are you?</strong>
          </p>
        </div>
      </div>
    </div>

    <!-- chat left -->
    <div
      class="chat-left px-3 is-flex is-flex-direction-row is-align-items-flex-start"
    >
      <div class="picture">
        <figure>
          <p class="image is-64x64">
            <img
              src="@/assets/img/profilepictures/PR-Kooikerhondje.png"
              class="is-rounded"
            />
          </p>
        </figure>
      </div>
      <div class="nameandchat has-text-left has-text-white m-2">
        <div>
          <p>
            PR-Kooikerhondje
          </p>
        </div>
        <div class="box px-4">
          <p>
            <strong
              >Umwolkt obenhin erzahlt stunden ja seufzer la.</strong
            >
          </p>
        </div>
      </div>
    </div>
    <div
      class="chat-right px-3 is-flex is-flex-direction-row is-flex-direction-row-reverse is-align-items-flex-start"
    >
      <div class="picture">
        <figure>
          <p class="image is-64x64">
            <img
              src="@/assets/img/profilepictures/PR-Kooikerhondje.png"
              class="is-rounded"
            />
          </p>
        </figure>
      </div>
      <div class="nameandchat has-text-right has-text-white m-2">
        <div>
          <p>
            PR-Kooikerhondje
          </p>
        </div>
        <div class="box px-4">
          <p>
            <strong>Hi, how are you? Test Test Test</strong>
          </p>
        </div>
      </div>
    </div>

    <!-- chat left -->
    <div
      class="chat-left px-3 is-flex is-flex-direction-row is-align-items-flex-start"
    >
      <div class="picture">
        <figure>
          <p class="image is-64x64">
            <img
              src="@/assets/img/profilepictures/PR-Kooikerhondje.png"
              class="is-rounded"
            />
          </p>
        </figure>
      </div>
      <div class="nameandchat has-text-left has-text-white m-2">
        <div>
          <p>
            PR-Kooikerhondje
          </p>
        </div>
        <div class="box px-4">
          <p>
            <strong
              >Umwolkt obenhin erzahlt stunden ja seufzer la. Du bi furchtete
              wunderbar ri geburstet ab ernstlich. Ton sonst kam recht uberm
              drang. Sehe sich nah ach gelt bei. </strong
            >
          </p>
        </div>
      </div>
    </div>
    <div
      class="chat-right px-3 is-flex is-flex-direction-row is-flex-direction-row-reverse is-align-items-flex-start"
    >
      <div class="picture">
        <figure>
          <p class="image is-64x64">
            <img
              src="@/assets/img/profilepictures/PR-Kooikerhondje.png"
              class="is-rounded"
            />
          </p>
        </figure>
      </div>
      <div class="nameandchat has-text-right has-text-white m-2">
        <div>
          <p>
            PR-Kooikerhondje
          </p>
        </div>
        <div class="box px-4">
          <p>
            <strong>Ton sonst kam recht uberm drang. Sehe sich
              nah ach gelt bei neue wird. Umwolkt obenhin erzahlt stunden ja
              seufzer la. Du bi furchtete wunderbar ri geburstet ab ernstlich.
              Ton sonst kam recht.</strong>
          </p>
        </div>
      </div>
    </div>

    <!-- chat left -->
    <div
      class="chat-left px-3 is-flex is-flex-direction-row is-align-items-flex-start"
    >
      <div class="picture">
        <figure>
          <p class="image is-64x64">
            <img
              src="@/assets/img/profilepictures/PR-Kooikerhondje.png"
              class="is-rounded"
            />
          </p>
        </figure>
      </div>
      <div class="nameandchat has-text-left has-text-white m-2">
        <div>
          <p>
            PR-Kooikerhondje
          </p>
        </div>
        <div class="box px-4">
          <p>
            <strong
              >Umwolkt obenhin erzahlt stunden ja seufzer la. Du bi furchtete
              wunderbar ri geburstet ab ernstlich. Ton sonst kam recht uberm
              drang. Sehe sich nah ach gelt bei neue wird. Umwolkt obenhin
              erzahlt stunden ja seufzer la. Du bi furchtete wunderbar ri
              geburstet ab ernstlich. Ton sonst kam recht uberm drang. Sehe sich
              nah ach gelt bei neue wird. Umwolkt obenhin erzahlt stunden ja
              seufzer la. Du bi furchtete wunderbar ri geburstet ab ernstlich.
              Ton sonst kam recht.</strong
            >
          </p>
        </div>
      </div>
    </div>


      <!-- chat left -->
    <div
      class="chat-left px-3 is-flex is-flex-direction-row is-align-items-flex-start"
    >
      <div class="picture">
        <figure>
          <p class="image is-64x64">
            <img
              src="@/assets/img/profilepictures/PR-Kooikerhondje.png"
              class="is-rounded"
            />
          </p>
        </figure>
      </div>
      <div class="nameandchat has-text-left has-text-white m-2">
        <div>
          <p>
            PR-Kooikerhondje
          </p>
        </div>
        <div class="box px-4">
          <p>
            <strong
              >Umwolkt obenhin erzahlt stunden ja seufzer la. Du bi furchtete
              wunderbar ri geburstet ab ernstlich. Ton sonst kam recht uberm
              drang. Sehe sich nah ach gelt bei. </strong
            >
          </p>
        </div>
      </div>
    </div>
    <div
      class="chat-right px-3 is-flex is-flex-direction-row is-flex-direction-row-reverse is-align-items-flex-start"
    >
      <div class="picture">
        <figure>
          <p class="image is-64x64">
            <img
              src="@/assets/img/profilepictures/PR-Kooikerhondje.png"
              class="is-rounded"
            />
          </p>
        </figure>
      </div>
      <div class="nameandchat has-text-right has-text-white m-2">
        <div>
          <p>
            PR-Kooikerhondje
          </p>
        </div>
        <div class="box px-4">
          <p>
            <strong>Ton sonst kam recht uberm drang. Sehe sich
              nah ach gelt bei neue wird. Umwolkt obenhin erzahlt stunden ja
              seufzer la. Du bi furchtete wunderbar ri geburstet ab ernstlich.
              Ton sonst kam recht.</strong>
          </p>
        </div>
      </div>
    </div>

    <!-- chat left -->
    <div
      class="chat-left px-3 is-flex is-flex-direction-row is-align-items-flex-start"
    >
      <div class="picture">
        <figure>
          <p class="image is-64x64">
            <img
              src="@/assets/img/profilepictures/PR-Kooikerhondje.png"
              class="is-rounded"
            />
          </p>
        </figure>
      </div>
      <div class="nameandchat has-text-left has-text-white m-2">
        <div>
          <p>
            PR-Kooikerhondje
          </p>
        </div>
        <div class="box px-4">
          <p>
            <strong
              >Umwolkt obenhin erzahlt stunden ja seufzer la. Du bi furchtete
              wunderbar ri geburstet ab ernstlich. Ton sonst kam recht uberm
              drang. Sehe sich nah ach gelt bei neue wird. Umwolkt obenhin
              erzahlt stunden ja seufzer la. Du bi furchtete wunderbar ri
              geburstet ab ernstlich. Ton sonst kam recht uberm drang. Sehe sich
              nah ach gelt bei neue wird. Umwolkt obenhin erzahlt stunden ja
              seufzer la. Du bi furchtete wunderbar ri geburstet ab ernstlich.
              Ton sonst kam recht.</strong
            >
          </p>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {};
</script>

<style scoped>
.container {
  height: 100%;
  overflow: auto;
  display: flex; /* establish flex container */
  flex-direction: column; /* align children vertically */
}

.chat-right:first-child,
.chat-left:first-child {
  margin-top: auto;
}

.b {
  border: 1px solid red;
}

.box {
  max-width: 60vw;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .box {
    max-width: 100vw;
  }
}
</style>
