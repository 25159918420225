<template>
  <section>
    <div class="hero is-fullheight is-dark">
      <div class="hero-head">
        <Nav />
      </div>
      <div class="hero-body has-text-white">
        Login
      </div>
    </div>
  </section>
</template>

<script>
import Nav from "../components/Nav/Nav";

export default {
  components: {
    Nav,
  },
};
</script>

<style scoped>

</style>
